@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';

.container {
	width: 100%;
	max-width: 600px;
	margin: 0 auto;

	border-radius: 5px;

	padding: 3rem;

	row-gap: 2rem;

	color: $text-black;

	background-color: $background-white;
}

.button_container {
	display: flex;
	flex-wrap: wrap;
	margin: 20px auto 0;

	gap: 12px;
}

.single_button_container {
	margin-bottom: 1rem !important;
}

.input_wrapper {
	position: relative;
	margin-top: 16px;
}

.input_wrapper input {
	width: 100%;
	display: block;
	padding: 12px;
	margin: 0;

	border-radius: $br-xs;
	border: 1px solid $border-grey-light;
	background: $background-white;

	font-size: 0.875rem;

	color: $text-grey-dark;
}

.show_password_button {
	position: absolute;
	right: 12px;
	top: 50%;
	transform: translateY(-50%);

	span {
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.already_login_button {
	color: $text-grey-dark;

	&:hover {
		text-decoration: underline;
	}
}

.error {
	margin-top: 16px;
	color: $text-error-red-dark-mode;
}

.success {
	margin-top: 16px;
	color: #28a745;
}

.modal_container {
	> div {
		width: auto;
		max-width: 600px;
	}
}
