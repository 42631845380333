@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2023';
@import 'styles/colors-2023';

.thank_you_message {
	@include subheading2;
	margin-bottom: 2rem;

	@include md-up {
		@include heading5;
		margin-bottom: 2rem;
	}
}

.confirmation_message {
	h3 {
		@include subheading4;
		@include md-up {
			@include subheading2;
		}
	}

	p {
		@include body5;
		margin-top: 0.25rem;
		opacity: 0.8;
	}
}

.confirmation_box {
	padding: 2rem;
	border-radius: 0.3125rem;
	border: 1px solid $neutral-4;
	margin-bottom: 1rem !important;

	p {
		@include body5;
		opacity: 0.8;
	}
}

.shipping_top_line {
	@include subheading4;

	display: flex;
	gap: 0.25rem;

	span {
		display: flex;
	}

	svg {
		width: 1.5rem;
		height: 1.5rem;
	}

	margin-bottom: 0.25rem;
}

.qr_box {
	display: grid;
	gap: 2rem;

	.qr_desktop {
		display: none;
	}

	@include md-up {
		grid-template-columns: auto 5.5rem;
		gap: 4rem;
		align-items: center;

		.qr_desktop {
			display: unset;
		}

		.app_link {
			display: none;
		}
	}
}

.shipping_grid {
	display: grid;
	gap: 1rem;

	@include md-up {
		grid-template-columns: 1fr 1fr;
	}

	h4 {
		@include subheading4;
		margin-bottom: 0.25rem;
	}

	address {
		@include body5;
		opacity: 0.8;
	}
}

.discover_membership {
	h3 {
		@include subheading3;

		margin-bottom: 1rem;

		@include md-up {
			@include subheading2;
			margin-bottom: 2rem;
		}
	}

	ul {
		list-style: none;
		margin: 0;

		svg,
		span {
			display: inline-flex;
			align-items: center;
			width: 1.5rem;
			height: 1.5rem;
		}

		p {
			display: inline;
			margin-left: 0.5rem;
		}
	}

	li {
		margin-bottom: 1rem;
		display: flex;
		align-items: center;
		gap: 0.5rem;
	}

	> p {
		margin: 1rem auto;
	}
}

.secondary_app_link {
	display: flex;
	justify-content: flex-end;
	margin-top: 2rem;

	@include sm-down {
		a {
			width: 100%;
		}
	}
}

.contact_email {
	word-break: break-all;
}

.shipping_line {
	display: flex;
	gap: 0.5rem;
}
